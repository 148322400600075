// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-deletion-js": () => import("./../../../src/pages/account-deletion.js" /* webpackChunkName: "component---src-pages-account-deletion-js" */),
  "component---src-pages-beebo-rider-apply-js": () => import("./../../../src/pages/beebo-rider/apply.js" /* webpackChunkName: "component---src-pages-beebo-rider-apply-js" */),
  "component---src-pages-beebo-rider-index-js": () => import("./../../../src/pages/beebo-rider/index.js" /* webpackChunkName: "component---src-pages-beebo-rider-index-js" */),
  "component---src-pages-community-standards-js": () => import("./../../../src/pages/community-standards.js" /* webpackChunkName: "component---src-pages-community-standards-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-faq-inner-js": () => import("./../../../src/pages/faq-inner.js" /* webpackChunkName: "component---src-pages-faq-inner-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-help-center-js": () => import("./../../../src/pages/help-center.js" /* webpackChunkName: "component---src-pages-help-center-js" */),
  "component---src-pages-how-to-js": () => import("./../../../src/pages/how-to.js" /* webpackChunkName: "component---src-pages-how-to-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-kit-js": () => import("./../../../src/pages/press-kit.js" /* webpackChunkName: "component---src-pages-press-kit-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-subscribed-js": () => import("./../../../src/pages/subscribed.js" /* webpackChunkName: "component---src-pages-subscribed-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

